import React from "react"
import Layout from "../components/layout/layout"
import SEOComponent from "../components/seo"
import Event from "../components/events/Event";
import NewsRoomPR from "../components/newsroom/NewsfeedPR";

const Events = () => (
    <Layout>
        <SEOComponent title="Amber by inFeedo | Events" description="Amber is an AI chatbot that talks to your employees to helps you measure employee experience in your organization. As Amber travels the world, catch all the action here. Be a part of our ever-growing community." />
        <Event />
        <NewsRoomPR />
    </Layout>
)

export default Events; 