import React, { Component } from 'react';
import EventCard from './EventCard';
import EventHead from './EventHead';
import event1 from '../../../static/images/VGDeventcombinedV.1.jpg'
import event2 from '../../../static/images/inFeedo4unicorneventV.1.jpg'
import event3 from '../../../static/images/inFeedoHRmentalhealtheventV.1.jpg'
import upcomingEventImg from '../../../static/images/upcoming_event_img.jpg'
import upcomingEventMobileImg from '../../../static/images/upcoming_event_mobile_img.jpg'
import noUpcomingEventImg from '../../../static/images/NoUpcominEventDesktop.png'
import noUpcomingEventMobileImg from '../../../static/images/NoUpcomingEventMobile.png'
import event4 from '../../../static/images/inFeedoHRconnectglobalsoniaeventV.1-26-min.jpg'
import event5 from '../../../static/images/high-culture-event.jpg'


export default class Event extends Component {

    state = {
        pastEvents: [
            {
                image: event5,
                date: '27th November, 2020',
                text: '6 Key Takeaways from HR Leaders on Building High Performance Cultures at Growing Startups',
                link: 'https://blog.infeedo.com/6-key-takeaways-from-hr-leaders-on-how-to-create-high-performance-cultures-at-growing-startups',
            },
            {
                image: event4,
                date: '27th August, 2020',
                text: '3 Key Tenets of the 2020 Workplace: Agile Innovation, Productivity, Diversity & Inclusivity',
                link: 'https://blog.infeedo.com/5-takeaways-from-hr-leaders-on-agility-diversity-and-productivity-in-the-modern-workplace',
            },
            {
                image: event3,
                date: '29 July 2020',
                text: 'The 2020 HR Evolution: Bridging Employee Mental Wellness with AI and Analytics',
                link: 'https://blog.infeedo.com/the-2020-hr-evolution-8-takeaways-on-bridging-employee-mental-wellness-gap-with-ai-and-analytics',
            },
            {
                image: event2,
                date: '16 July 2020',
                text: '4 Unicorn Startup HR Leaders Answer Burning Crisis Management Questions',
                link: 'https://blog.infeedo.com/9-takeaways-from-our-power-panel-hr-essentials-for-unicorn-startups-during-covid-19',
            },
            {
                image: event1,
                date: '16 April - 19 June 2020',
                text: 'Virtual Group Discussions with HR Leaders',
                link: ' https://blog.infeedo.com/handbook-20-takeaways-from-hr-thought-leaders-on-tackling-the-new-normal-of-work',
            }
        ],
        upcomingEvent:
        {
            image: upcomingEventImg,
            mobileImg: upcomingEventMobileImg,
            place: 'Digital Event',
            time: '12:30 pm (IST)',
            date: '4th Nov, 2020',
            date_compare: '2020-11-4',
            link: 'https://getstarted.infeedo.com/india-event-register?utm_campaign=India%20Event%20OND%20&utm_source=email&utm_medium=email&utm_term=signature&utm_content=signature',
            no_upcoming_event_img: noUpcomingEventImg,
            no_upcoming_event_mobile_img: noUpcomingEventMobileImg,
            no_upcoming_event_text: "We don't have an event coming up yet, <b>sign up</b> here and we'll keep you posted. Meanwhile, you may watch this <b>TechHR video.</b>",
            no_upcoming_event_link: 'OJJCBc2IoVs'
        }
        ,
        one: [],
        two: [],
        three: []
    }

    componentDidMount() {
        this.createVerticalArrays();
    }

    createVerticalArrays = () => {
        const one = [];
        const two = [];
        const three = [];
        const posts = this.state.pastEvents
        posts.forEach((article, index) => {
            if (index % 3 === 0) {
                one.push(article);
            }
            if (index % 3 === 1) {
                two.push(article);
            }
            if (index % 3 === 2) {
                three.push(article);
            }
        });
        this.setState({ one, two, three });
    }

    render() {
        const { one, two, three, pastEvents, upcomingEvent } = this.state;
        return (
            <div className="pd-15-mobile event-page-padding">
                <EventHead card_data={upcomingEvent} />
                <div className="pd-80 event-page-innerPadding has-text-centered-mobile">
                    <strong className="is-size-3 is-size-4-mobile mg-bottom-20">Past Events</strong>
                </div>
                <div className="tile  mg-top-20">
                    <div className="tile is-vertical is-4 flex-wrap is-hidden-mobile">
                        {one.map((article, index) => (
                            <div key={index}>
                                <div className="tile is-parent is-vertical mg-10">
                                    <EventCard card_data={article} />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="tile is-vertical is-4 flex-wrap is-hidden-mobile">
                        {two.map((article, index) => (
                            <div key={index}>
                                <div className="tile is-parent is-vertical mg-10">
                                    <EventCard card_data={article} />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="tile is-vertical is-4 flex-wrap is-hidden-mobile">
                        {three.map((article, index) => (
                            <div key={index}>
                                <div className="tile is-parent is-vertical mg-10">
                                    <EventCard card_data={article} />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="tile is-vertical is-4 flex-wrap is-hidden-tablet">
                        {pastEvents.map((article, index) => (
                            <div key={index}>
                                <div className="tile is-parent past-event-mobileView is-vertical mg-10">
                                    <EventCard card_data={article} />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}
