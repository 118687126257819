import React from "react";
import PropTypes from "prop-types";

const EventCard = ({ card_data }) => (
    <div className="card card-shadow card-hov">
        {
            card_data.image ?
                <div className="card-image">
                    <figure className="image">
                        <img src={card_data.image} className="radius-top-10" alt="Placeholder" />
                    </figure>
                </div> : null
        }
        <div className="card-content pd-top-15  ">
            <div className="media mg-bottom-20">
                {card_data.date ?
                    <div className="media-content">
                        <p className="title  past-event-date has-text-weight-bold pd-top-5 no-overflow">{card_data.date}</p>
                    </div> : null
                }
            </div>
            <div className="content  has-text-left font-17 is-family-poppins">
                <p className=" has-text-left font-17 is-family-poppins height-75"> {card_data.text}</p>
            </div>
            <div>
                <a href={card_data.link} target="_blank" rel="noopener noreferrer">
                    <p className="font-14 has-text-weight-medium has-text-link">Read Takeaways &rarr;</p>
                </a>
            </div>
        </div>
    </div>
)

EventCard.propTypes = {
    card_data: PropTypes.object,
}

export default EventCard